<template>

<v-autocomplete
      v-model="streamers"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      menu-props="closeOnContentClick"
      hide-details
      hide-selected
      hide-no-data
      item-text="display_name"
      label="Rechercher un streamer"
      return-object
      chips
      solo
    >
      <template v-slot:item="{ item }">
        <v-list-item-avatar
          color="indigo"
          class="text-h5 font-weight-light white--text"
        >
          <img :src="item.thumbnail_url">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="item.display_name"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>mdi-twitch</v-icon>
        </v-list-item-action>
      </template>
    </v-autocomplete>
</template>
<style>
  .autocomplete-form {
    max-width: 400px;
    margin: 50px auto 0 auto;
  }
  .theme--light.v-label,
  .theme--light.v-icon {
    color: #fff;
  }
  .v-select.v-select--chips input {
    color: #fff;
  }
  .theme--light .theme--light.v-list {
    background: #6572a6;
  }
  .v-list-item .v-list-item__title, .v-list-item {
    padding: 0 20px;
  }
  .v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
    color: #fff;
  }
  .theme--light .theme--light.v-list-item:hover::before {
    opacity: 0.15;
  }
  .theme--light .theme--light.v-chip:not(.v-chip--active) {
    background: #586699;
    color: #fff;
  }
</style>
<script>
    const bearer = 'mq1x9vp9117n2ab3bu42mcyck0qwpk'
    export default {
      name: 'Autocomplete',
      data: () => ({
          isLoading: false,
          search: null,
          streamers: [],
          items: []
      }),
      watch: {
        search (val) {

          clearTimeout(this._timerId);
          
          this._timerId = setTimeout(() => {
            this.isLoading = true
            if(val != null && val != ''){
              fetch('https://api.twitch.tv/helix/search/channels?query='+val, {
                  headers: {
                      'Authorization': `Bearer ${bearer}`,
                      'client-id': 'n7j48851a3zt5c4raeyravwdhafxoo'
                  }
              })
              .then(res => res.clone().json())
              .then(res => {
                if(this.items.length > 0){
                  this.items = this.items.concat(res.data)
                }else{
                  this.items = res.data
                }
              })
              .catch(err => {
                  console.log(err)
              })
              .finally(() => (this.isLoading = false))
            }else{
              this.items = []
            }
            
          }, 1000)
        },
        streamers(val) {
          this.$emit('streamtoadd', val);
          this.$nextTick(() => {
              this.streamers = null
              this.items = []
              document.activeElement.blur()
          })
        }
      },
    }
</script>