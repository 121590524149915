import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Watch from '../views/Watch.vue'
import Event from '../views/Event.vue'
import UpdateEvents from '../views/UpdateEvents.vue'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/watch',
    name: 'Watch',
    component: Watch
  },
  {
    path: '/event/:id',
    name: 'Event',
    component: Event
  },
  {
    path: '/update/events',
    name: 'UpdateEvents',
    component: UpdateEvents
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
