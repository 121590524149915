<template>
    <div class="watch-wrapper" data-app>
        <div class="streams">
            <div v-for="streamer in streamers" :key="streamer.id" class="stream-item" :style="{'height':streamer.height,'width':streamer.width}">
                <Player :streamer="{streamer}" />
            </div>
        </div>
        <div class="chat">
            <div class="chat-header">
                <select @change="changeChat($event)">
                    <option style="display:none;">Changer le chat</option>
                    <option :value="streamer.id" v-for="streamer in streamers" :key="streamer.id">{{ streamer.id }}</option>
                </select>
                <div :class="['menu-trigger', { active }]" @click="active = !active">
                    <svg height="32px" id="Layer_1" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/></svg>
                </div>
                <div class="menu">
                    <div class="streamers-list">
                        <h3>Streamers présents</h3>
                        <div class="streamer-chip" v-for="(streamer, index) in streamers" :key="streamer.id">
                            {{ streamer.id }}
                            <button type="button" aria-label="Close" class="remove-streamer v-icon notranslate v-chip__close v-icon--link v-icon--right mdi mdi-close-circle theme--light" @click="removeStream(index)"></button>
                        </div>
                    </div>
                    <div class="streamers-add">
                        <h3>Ajouter un streamer</h3>
                        <AutocompleteAdd @streamtoadd="getStreamToAdd"/>
                    </div>
                </div>
                
            </div>
            <iframe twitch-chat :src="chatSrc" frameborder="0"></iframe>
        </div>
        <!--v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        >
        Si vous rencontrez des problèmes de lecteur "violet", vous pouvez télécharger l'extention chrome <a href="https://chrome.google.com/webstore/detail/purple-ads-blocker/lkgcfobnmghhbhgekffaadadhmeoindg" target="_blank" class="snackbar-link">Purple Ads Blocker</a>

        <template v-slot:action="{ attrs }">
            <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Fermer
            </v-btn>
        </template>
        </v-snackbar-->
    </div>
</template>
<style lang="scss">
    .watch-wrapper {
        height: 100vh;
        display: flex;
    }
    .streams {
        height: 100vh;
        width: calc( 100% - 340px );
        line-height: 0;
        display: flex;
        flex-wrap: wrap;
    }
    .chat {
        width: 340px;
        position: relative;
    }
    .chat iframe {
        width: 100%;
        height: calc( 100vh - 40px );
    }
    .change-chat {
        background: #2e354f;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    .change-chat span {
        color: #fff;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 600;
        padding: 16px 20px;
        display: block;
        cursor: pointer;
        position: relative;
        border-bottom: 1px solid #fff;
    }
    .change-chat span svg {
        width: 20px;
        height: 20px;
        margin-left: 20px;
        position: absolute;
        top: 14px;
        right: 20px;
    }
    .change-chat ul {
        list-style-type: none;
        display: none;
        padding: 0;
        margin: 0;
    }
    .change-chat ul.opened {
        list-style-type: none;
        display: block;
    }
    .change-chat ul li {
        color: #fff;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 600;
        cursor: pointer;
        border-bottom: 1px solid #fff;
        padding: 20px;
        transition: background 0.5s ease;
    }
    .change-chat ul li:hover {
        background: #262b40;
    }
    .chat-header {
        display: flex;
        select {
            flex-grow: 1;
            background-color: white;
            color: white;
            border-radius: 0;
            padding: 0 10px;
            outline: 0;
            background: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg) no-repeat right 0.8em center / 1.4em, linear-gradient(to left, #586699 3em, #262b40 3em);
            cursor: pointer;
            option {
                background-color: white;
                border-radius: 0;
                &:not(:first-child){
                    color: #262b40;
                }
            }
        }
        .menu-trigger {
            padding: 0 5px;
            cursor: pointer;
            svg {
                fill: white;
            }
        }
        .menu {
            display: none;
        }
        .active {
            + .menu {
                display: block;
                background: #262b40;
                position: absolute;
                width: 100%;
                left: 0;
                padding: 20px;
                top: 36px;
            }
        }
    }
    .streamers-list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        h3 {
            width: 100%;
            margin-bottom: 15px;
            text-align: left;
        }
        .streamer-chip {
            background: #586699;
            width: fit-content;
            padding: 3px 14px;
            border-radius: 15px;
            margin: 0 8px 8px 0;
            height: fit-content;
            .remove-streamer {
                max-height: 18px;
                max-width: 18px;
            }
        }
    }
    .streamers-add {
        h3 {
            margin-bottom: 15px;
            text-align: left;
        }
    }
 
    .v-autocomplete {
        .v-input__control {
            min-height: 48px;
        }
    }
    
</style>
<script>
    import Player from "@/components/Player.vue";
    import AutocompleteAdd from '@/components/AutocompleteAdd'

  export default {
    name: 'Watch',
    data: () => ({
        length: 0,
        streamers: [],
        firstStreamerId: '',
        opened: false,
        chatSrc: '',
        snackbar: false,
        timeout: 60000,
        active: ''
    }),
    components: {
        Player,
        AutocompleteAdd
    },
    methods: {
        changeChat(event){
            this.chatSrc = 'https://www.twitch.tv/embed/'+event.target.value+'/chat?parent=www.multi-streams.com&darkpopout' 
            this.opened = false
        },
        removeStream(i){
            this.streamers.splice(i, 1);
            this.length = this.streamers.length;
            let streamers_ids = []
            this.streamers.forEach((element, i) => {
                element.width = this.getWidth(i)
                element.height = this.getHeight()
                streamers_ids.push(element.id)
            })
            this.$router.push({ name: 'Watch', query: { streamers: streamers_ids.join() }})
        },
        getStreamToAdd(value){
            if(value){
                this.streamers.push({
                    'id': value.broadcaster_login,
                    'width': this.getWidth(this.length + 1),
                    'height': this.getHeight()
                })
                let streamers_ids = this.$route.query.streamers.split(",")
                streamers_ids.push(value.broadcaster_login)
                this.$router.push({ name: 'Watch', query: { streamers: streamers_ids.join() }})
                this.reloadSizes()
            }
        },
        reloadSizes(){
            this.length = this.streamers.length
            this.streamers.forEach((element, i) => {
                element.width = this.getWidth(i)
                element.height = this.getHeight()
            })
        },
        getWidth(i){
            if(this.length < 3){
                return '100%'
            }
            if(this.length == 3){
                if(i == 0){
                    return '100%'
                }
                return '50%'
            }
            if(this.length == 4){
                return '50%'
            }
            if(this.length == 5){
                if(i < 2){
                    return '50%'
                }
                return '33.3333%'
            }
            if(this.length == 6){
                return '33.3333%'
            }
            if(this.length == 7){
                if(i < 3){
                    return '33.3333%'
                }
                return '25%'
            }
            if(this.length == 8){
                return '25%'
            }
            if(this.length == 9){
                return '33.3333%'
            }
            if(this.length == 10){
                if(i < 6){
                    return '33.3333%'
                }
                return '25%'
            }
            if(this.length == 11){
                if(i < 3){
                    return '33.3333%'
                }
                return '25%'
            }
            if(this.length == 12){
                return '25%'
            }
            if(this.length == 13){
                if(i < 9){
                    return '33.3333%'
                }
                return '25%'
            }
            if(this.length == 14){
                if(i < 6){
                    return '33.3333%'
                }
                return '25%'
            }
            if(this.length == 15){
                if(i < 3){
                    return '33.3333%'
                }
                return '25%'
            }
            if(this.length > 15){
                return '25%'
            }
        },
        getHeight(){
            if(this.length == 1){
                return '100%'
            }
            if(this.length < 9){
                return '50%'
            }
            if(this.length < 13){
                return '33.3333%'
            }
            if(this.length >= 13){
                return '25%'
            }
        }
    },
    mounted(){
        this.snackbar = true
        let streamers_ids = this.$route.query.streamers.split(",")
        this.length = streamers_ids.length
        streamers_ids.forEach((element, i) => {
            this.streamers.push({
                'id': element,
                'width': this.getWidth(i),
                'height': this.getHeight()
            })
        })  
        this.firstStreamerId = this.streamers[0].id 
        this.chatSrc = 'https://www.twitch.tv/embed/'+this.firstStreamerId+'/chat?parent=www.multi-streams.com&darkpopout'   
    }
  }
</script>