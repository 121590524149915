<template>
    <div class="update">
        {{ status }}
    </div>
</template>

<script>
import { db } from '../db'
import { getDocs, collection, updateDoc, doc } from "firebase/firestore";

export default {
    name: 'UpdateEvents',
    data: () => ({
        events: [],
        eventsFull: [],
        status: 'Updating...'
    }),
    async mounted(){
      window.setInterval(() => {
        location.reload(true)
      }, 120000)
      const querySnapshot = await getDocs(collection(db, "events"));
      querySnapshot.forEach((doc) => {
        this.eventsFull.push({'data': doc.data(), 'id': doc.id})
      })
      this.eventsFull.forEach(element => {
        let streamers = [];
        element.data.streamers.forEach(element => {
          streamers.push(
            element.username,
          );
        });
        this.events.push({
          "id": element.id,
          "streamers": streamers,
        });
      });
        this.events.forEach(element => {
            let streamersIds = element.streamers.join('&user_login=')
            this.$customfunc.getStreamsData(streamersIds)
            .then(res => {
                let streamersOnline = res.streams
                this.$customfunc.getUsersData(streamersIds)
                .then(res => {
                    this.$customfunc.concatStreamers(streamersOnline, res)
                    .then(res => {
                      this.streamers = res
                      const docRef = doc(db, "events", element.id)
                      updateDoc(docRef, {
                        streamers: this.streamers
                      });
                      this.status = 'Updated :)'
                    })
                })
            })
        })
    }
}
</script>