<template>
<div>
  <v-container>
    <v-row class="text-center" align="center" justify="center">
      <v-col cols="12" class="listing-header">
        <v-img
            :src="image"
        >
        </v-img>
      </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" class="listing-head">
          <router-link :to="'/'">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" class="svg-inline--fa fa-chevron-left fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path></svg>
            Retour à l'accueil
          </router-link>
          <h1>Participants au {{ title }}</h1>
        </v-col>
        <!--v-col cols="12">
            <p>{{ description }}</p>
        </v-col-->
    </v-row>
    <v-row class="streamers">
        
      <v-col
        cols="12"
      >
        <p>Choisi les streamers que tu souhaites regarder en multi-stream</p>
      </v-col>
        <v-col cols="12" class="listing">
          <div v-for="streamer in streamers" :key="streamer.id" :class="'listing-item '+ [streamer.online ? 'online' : 'offline']+ [selectedStreamers.includes(streamer.username) ? ' selected' : '']" @click="switchSelected(streamer.username)">
              <div class="icon">
                  <img :src="streamer.icon">
              </div>
              <div class="infos">
                  <span class="name">{{ streamer.name }}</span>
                  <span class="viewers" v-if="streamer.viewers">{{ streamer.viewers }} spectateurs</span>
                  <span class="viewers" v-else>Hors-ligne</span>
                  <span class="game invalid">{{ streamer.game }}</span>
                  <span class="bullet"></span>
              </div>
          </div>
        </v-col>
    </v-row>
  </v-container>
    <div class="banner" v-if="selectedStreamers[0]">
        <div class="banner-icons" selected-streamers>
            <div v-for="streamer in streamers" :key="streamer.id" class="banner-icon" :selected-stream="streamer.username">
                <img :src="streamer.icon" v-if="selectedStreamers.includes(streamer.username)">
            </div>
        </div>
        <div class="banner-button">
            <a @click="launch">Ouvrir le multistream</a>
        </div>
    </div>
  <!--v-footer app bottom fixed padless>
    <v-container>
      <v-row>
      <v-col
        align="left"
        cols="6"
      >
        {{ new Date().getFullYear() }} — <strong>Multi-streams</strong>
      </v-col>
      <v-col align="right" cols="6">
        <v-btn href="https://steamcommunity.com/id/leeroy521" target="_blank">Contact steam</v-btn>
        <button @click="deco" v-if="userstate">Déconnexion</button>
      </v-col>
      </v-row>
    </v-container>
  </v-footer-->
  <!--v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      Si vous rencontrez des problèmes de lecteur "violet", vous pouvez télécharger l'extention chrome <a href="https://chrome.google.com/webstore/detail/purple-ads-blocker/lkgcfobnmghhbhgekffaadadhmeoindg" target="_blank" class="snackbar-link">Purple Ads Blocker</a>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar-->
  </div>
</template>
<style>
  h1 {
    font-size: 50px;
    margin-bottom: 20px;
  }
  .listing-header {
    max-width: 1200px;
    margin: 0 auto;
    max-height: 300px;
    margin-bottom: 50px;
  }
  .listing-header .v-image__image,
  .listing-header .v-image,
  .listing-header .v-responsive__sizer {
    max-height: 300px;
  }
  .listing-head {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 50px;
  }
  .listing-head a {
    position: absolute;
    left: 15px;
    top: 32px;
    color: #fff;
  }
  .listing-head a svg {
    width: 10px;
    height: 10px;
  }
  .listing {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    max-width: 1200px;
    margin: 0 auto 100px;
    margin-top: 50px;
}
.streamers {
  padding-bottom: 300px;
}
.listing-item {
    position: relative;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    opacity: 0.5;
    cursor: pointer;
    transition: background 0.5s ease;
}
.listing-item.online {
    opacity: 1;
}
.listing-item.selected,
.listing-item:hover {
    background: #0f1f38;
}
.icon img {
    width: 80px;
    border-radius: 5px;
}
.infos {
    width: calc( 100% - 100px );
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
}
.infos span {
    display: block;
}
.name {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    flex-grow: 1;
}
.viewers {
    margin-bottom: 5px;
}
.viewers i {
    font-weight: 600;
    font-style: normal;
}
.viewers,
.game {
    font-size: 15px;
}
.game {
    font-size: 13px;
}
.game.invalid {
    opacity: 0.5;
}
.bullet {
    display: block;
    background: red;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.online .bullet {
    background: green;
}
.banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #0f1f38;
    padding: 30px 50px;
    align-items: center;
    display: flex;
}
.banner.visible {
    display: flex;
}
.banner-icons {
    display: flex;
    flex-wrap: wrap;
    width: calc( 100% - 300px );
    justify-content: center;
}
.banner-icon img {
    width: 75px;
    padding: 5px;
}
.banner-button {
    width: 300px;
}
.banner-button a {
    color: #000;
    text-decoration: none;
    background: #fff;
    padding: 15px 60px;
    font-size: 18px;
    border-radius: 30px;
    display: inline-block;
    transition: background 0.5s ease;
    cursor: pointer;
}
.banner-button a:hover {
    background: #2c3145;
    color: #fff;
}
</style>
<script>
  import { db } from '../db'
  import { doc, getDoc } from "firebase/firestore";
  import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

  export default {
    name: 'Event',

    data: () => ({
      title: '',
      description: '',
      image: '',
      streamers: [],
      selectedStreamers: [],
      userstate: null,
      snackbar: false,
      timeout: 60000
    }),
    async mounted(){
      this.snackbar = true
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.userstate = true
        } else {
          this.userstate = false
        }
      });
      const docRef = doc(db, "events", this.$route.params.id);
      const docSnap = await getDoc(docRef);
      let data = docSnap.data()
      this.title = data.title
      this.description = data.description
      this.image = data.image
      this.streamers = data.streamers
      this.streamers.sort(function(a, b) {
        return a.name - b.name;
      })
      this.streamers.sort(function(a, b) {
        return b.viewers - a.viewers;
      })

    },
    methods: {
      deco () {
        const auth = getAuth();
        signOut(auth).then(() => {
          this.$router.push({ name: 'Home'})
        }).catch(() => {
          console.log('Il y a eu une erreur lors de la déconnexion.')
        });
      },
      launch (){
        let value = this.selectedStreamers.join()
        this.$router.push({ name: 'Watch', query: { streamers: value }})
      },
      switchSelected(username){
          if(!this.selectedStreamers.includes(username)){
            if(this.selectedStreamers.length < 16){
              this.selectedStreamers.push(username)
            }else{
              alert('Impossible d\'ajouter plus de 16 streams')
            }
            
          }else{
            this.selectedStreamers.splice(this.selectedStreamers.indexOf(username), 1);
          }
          
      }
    }
  }
</script>