<template>
<div class="home">
  <v-container>
    <v-row class="text-center" align="center" justify="center">
      <v-col cols="12">
        <h1>Multi-Streams</h1>
        <p>Ajoute des streamers à ton multistream</p>
        <v-form ref="form" lazy-validation class="autocomplete-form" @submit.prevent="launch">
          <Autocomplete/>
          <v-btn
          type="submit"
              class="mr-4 launch-stream-button"
              >
              Lancer
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-row class="events">
      <v-col
        cols="9"
      >
        <h2 class="headline font-weight-bold mb-3">
          Événements
        </h2>

        <v-row>
          <!--v-col cols="12" 
            v-for="(event, i) in events"
            :key="i"
            class="d-flex event-wrapper" style="flex-direction:column"
          >
            <EditEvent v-if="event.userid && userId == event.userid" :event='event' />
            <router-link :to="{
              name: 'Event',
              params: { id: event.id }
            }">
              <v-card class="mb-1 flex-grow-1">
                <v-img
                  class="white--text align-end"
                  height="300px"
                  :src="event.image"
                >
                </v-img>
                <v-card-text class="text--primary">
                  <h3>{{ event.title }}</h3>
                  <div>{{ event.description }}</div>
                </v-card-text>
              </v-card>
            </router-link>
          </v-col-->

          <v-col cols="6" data-app>
          <v-card class="mb-1 flex-grow-1">
              <v-img
                class="white--text align-end"
                height="300px"
                src="https://images.squarespace-cdn.com/content/v1/5b5e6a9996e76f18347bf870/1598836530730-T6GD3KWWJF5EPX28UXIU/Video%2Bstream.jpg"
              >
              </v-img>
              <v-card-text class="text--primary wrapper-add-event-card">
                <v-btn disabled>Ajouter un événement (bientôt)</v-btn>
                <!--Login v-if="userId == 327894327894328" />
                <AddEvent v-if="userId" /-->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>        
      </v-col>
    </v-row>
  </v-container>
  <!--v-footer app bottom fixed padless>
    <v-container>
      <v-row>
      <v-col
        align="left"
        cols="6"
        class="footer-left"
      >
        {{ new Date().getFullYear() }} — <strong>Multi-streams</strong>
      </v-col>
      <v-col align="right" cols="6">
        <v-btn href="https://steamcommunity.com/id/leeroy521" target="_blank">Contact steam</v-btn>
        <v-btn @click="deco" v-if="userId">Déconnexion</v-btn>
      </v-col>
      </v-row>
    </v-container>
  </v-footer-->
    <!--v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      Si vous rencontrez des problèmes de lecteur "violet", vous pouvez télécharger l'extention chrome <a href="https://chrome.google.com/webstore/detail/purple-ads-blocker/lkgcfobnmghhbhgekffaadadhmeoindg" target="_blank" class="snackbar-link">Purple Ads Blocker</a>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar-->
  </div>
</template>
<style>
  html {
    overflow: auto;
  }
  body {
    padding: 0;
    margin: 0;
  }
  .home {
    padding-top: 50px;
  }
  h1 {
    font-size: 50px;
    margin-bottom: 20px;
  }
  h2 {
    margin-bottom: 50px;
    margin-top: 50px;
    border-top: 1px solid #000;
    padding-top: 50px;
    font-size: 35px;
  }
 .theme--light.v-btn.v-btn--has-bg.launch-stream-button {
    margin-top: 30px;
    background: #7382bd;
    color: #fff;
    width: 400px;
    font-weight: 600;
    height: 45px;
    font-size: 16px;
  }
  .events > .col {
    margin: 0 auto;
  }
  .event-wrapper {
    position: relative;
  }
  .theme--dark.v-btn.v-btn--has-bg,
  .theme--light.v-btn.v-btn--has-bg {
    background: #7382bd !important;
    color: #fff;
    font-weight: 600;
  }
  .edit-button {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 8;
    padding: 0;
    width: 40px;
    min-width: 0 !important;
  }
  .edit-button svg {
      width: 20px;
  }
  .footer-left {
    display: flex;
    align-items: center;
  }
  .wrapper-add-event-card {
    padding: 20px 0;
  }
  .v-text-field__slot input,
  .theme--light.v-input textarea {
    color: #fff !important;
  }
  #app .theme--light.v-autocomplete > .v-input__control > .v-input__slot {
    background: #6572a6;
  }
  .user-login-card {
    padding-bottom: 40px;
  }
  .theme--light.v-label.v-label--active {
    left: -14px !important;
    top: 9px !important;
  }
  .current-image {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .v-snack__wrapper a.snackbar-link {
    color: #fff;
    text-decoration: underline;
  }
</style>
<script>
  import { db } from '../db'
  import { collection, getDocs } from "firebase/firestore";
  import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
  //import Login from '@/components/Login'
  //import AddEvent from '@/components/AddEvent'
  //import EditEvent from '@/components/EditEvent'
  import Autocomplete from '@/components/Autocomplete'

  export default {
    name: 'Home',

    data: () => ({
      events: [],
      hover: false,
      overlay: false,
      userId: null,
      snackbar: false,
      timeout: 60000
    }),
    async mounted(){
      this.snackbar = true
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.userId = user.uid
        }
      });
      const querySnapshot = await getDocs(collection(db, "events"));
      querySnapshot.forEach((doc) => {
        const eventData = doc.data()
        eventData.id = doc.id
        this.events.push(eventData)
      });
    },
    methods: {
      deco () {
        const auth = getAuth();
        signOut(auth).then(() => {
          this.userId = null
          this.$router.push({ name: 'Home'})
        }).catch(() => {
          console.log('Il y a eu une erreur lors de la déconnexion.')
        });
      },
      launch (){
        let value = this.$refs.form.inputs[0].value.join()
        this.$router.push({ name: 'Watch', query: { streamers: value }})
      }
    },
    components: {
      //Login,
      //AddEvent,
      //EditEvent,
      Autocomplete
    }
  }
</script>