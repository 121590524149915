import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAJw6oeNJ9A7zTt1EfeJBhF3Jd8R0eMtxM",
  authDomain: "multi-stream-521.firebaseapp.com",
  databaseURL: "https://multi-stream-521-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "multi-stream-521",
  storageBucket: "multi-stream-521.appspot.com",
  messagingSenderId: "1036046671232",
  appId: "1:1036046671232:web:a3d1864c57f53d049d7565",
  measurementId: "G-P57QG5XXTM"
};

const app = initializeApp(firebaseConfig);

// Get a Firestore instance
export const db = getFirestore(app);

export const analytics = getAnalytics(app);