<template>
    <div class="player">
        <iframe :src="'https://player.twitch.tv/?channel='+streamer.streamer.id+'&parent=www.multi-streams.com&muted=true'" frameborder="0" allowfullscreen="true" scrolling="no"></iframe>
    </div>
</template>
<style lang="scss">
    .player {
        position: relative;
        width: 100%;
        height: 100%;
        iframe {
            width: 100%;
            height:100%;
        }
    }
    
</style>
<script>
  export default {
    name: 'Player',
    props: ['streamer']
  }
</script>