var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"menu-props":"closeOnContentClick","chips":"","hide-details":"","hide-selected":"","hide-no-data":"","item-text":"display_name","item-value":"broadcaster_login","label":"Rechercher un streamer","multiple":"","solo":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey","close":""},on:{"click:close":function($event){return _vm.remove(item)}}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-twitch ")]),_c('span',{domProps:{"textContent":_vm._s(item.display_name)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"color":"indigo"}},[_c('img',{attrs:{"src":item.thumbnail_url}})]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.display_name)}})],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-twitch")])],1)]}}]),model:{value:(_vm.streamers),callback:function ($$v) {_vm.streamers=$$v},expression:"streamers"}})}
var staticRenderFns = []

export { render, staticRenderFns }