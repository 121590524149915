import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
const bearer = 'mq1x9vp9117n2ab3bu42mcyck0qwpk'
export const customfunc = {
    getStreamsData: (usernames) => {
        return new Promise((resolve, reject) => {
            fetch('https://api.twitch.tv/helix/streams?first=100&user_login='+usernames, {
                headers: {
                    'Authorization': `Bearer ${bearer}`,
                    'client-id': 'n7j48851a3zt5c4raeyravwdhafxoo'
                }
            })
            .then(res => res.json())
            .then(res => {
                let remainingStreamers = usernames.split("&user_login=")
                let newData = []
                res.data.forEach(element =>{
                    newData.push({
                        'id': element.id,
                        'username': element.user_login,
                        'name': element.user_name,
                        'icon': element.thumbnail_url.replace("{width}", "300").replace("{height}", "300"),
                        'online': true,
                        'game': element.game_name,
                        'viewers': element.viewer_count
                    })
                })
                res.data.forEach(element =>{
                    if(remainingStreamers.includes(element.user_login)){
                        remainingStreamers.splice(remainingStreamers.indexOf(element.user_login), 1)
                    }
                })
                let resolveData = {streams: newData, remaining: remainingStreamers}
                resolve(resolveData)    
            })
            .catch(err => {
                reject(err)
            })


        })
    },
    getUsersData(remainingStreamers){
        return new Promise((resolve, reject) => {           
            let remainingStreamers2 = remainingStreamers.replaceAll('user_login', 'login')

            return fetch('https://api.twitch.tv/helix/users?first=100&login='+remainingStreamers2, {
                headers: {
                    'Authorization': `Bearer ${bearer}`,
                    'client-id': 'n7j48851a3zt5c4raeyravwdhafxoo'
                }
            })
            .then(res => res.json())
            .then(res => {
                let newDataOffline = []
                res.data.forEach(element =>{
                    newDataOffline.push({
                        'id': element.id,
                        'username': element.login,
                        'name': element.display_name,
                        'icon': element.profile_image_url,
                        'online': false,
                        'game': '',
                        'viewers': 0
                    })
                })
                resolve(newDataOffline)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    saveImage(image){
        return new Promise((resolve, reject) => {
            const storage = getStorage();
            const metadata = {
              contentType: image.type
            };
            const storageRef = ref(storage, 'images/' + image.name);
            const uploadTask = uploadBytesResumable(storageRef, image, metadata);
            uploadTask.on('state_changed',
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
              }, 
              (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                reject(error)
              }, 
              () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  resolve(downloadURL)
                });
              }
            );
        })
    },
    concatStreamers(online, offline){
        return new Promise((resolve) => {
            let streamers = []
            let streamersNames = []
            if(offline.length && online.length){
                offline.forEach(element => {
                    online.forEach(element2 => {
                        if(element.username == element2.username){
                            element.game = element2.game
                            element.online = true
                            element.viewers = element2.viewers
                        }
                        if(streamersNames.indexOf(element.username) == -1){
                            streamersNames.push(element.username)
                            streamers.push(element)
                        }
                    })
                })
            }else if(online.length && !offline.length){
                online.forEach(element => {
                    streamers.push(element)
                })
            }else{
                offline.forEach(element => {
                    streamers.push(element)
                })
            }
            
            resolve(streamers)
        })
    }
 }